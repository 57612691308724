<template>
  <div>
    <v-form ref="form">
      <div class="imgUserLogin mb-5">
        <img :src="imgLogo" alt="" />
      </div>
      <div class="text-center mb-5">
        <h1 class="titleLoginInicio">Inicio de Sesión</h1>
      </div>
      <v-text-field
        dark
        autocomplete="false"
        filled
        rounded
        dense
        v-model="formulario.nombre"
        label="Usuario"
      >
      </v-text-field>
      <v-text-field
        dark
        autocomplete="false"
        filled
        rounded
        dense
        v-model="formulario.clave"
        label="Contraseña"
        type="password"
      >
      </v-text-field>
      <div class="divBtnLoginOk">
        <v-btn
          @click="validate()"
          rounded
          elevation="0"
          class="btnLoginOk"
          block
        >
          Ingresar</v-btn
        >
      </div>
    </v-form>
  </div>
</template>
<script>
import axios from "axios";
import imgUser from "../../../public/assets/img/login/user.svg";
import imgLogo from "../../../public/assets/img/login/logo.svg";
import imgOnda from "../../../public/assets/img/login/onda-top.png";
export default {
  name: "LoginComponents",
  data: () => ({
    formulario: {},
    imgUser: imgUser,
    imgLogo: imgLogo,
    imgOnda: imgOnda,
  }),
  methods: {
    async validate() {
      this.error = "";
      if (this.$refs.form.validate()) {
        var data = {
          user: this.formulario.nombre,
          password: this.formulario.clave,
        };
        var config = {
          method: "post",
          url: process.env.VUE_APP_URL_MAIN + "singin",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        await axios(config)
          .then((response) => {
            var data = response.data;
            if (data.statusBol) {
              localStorage.setItem("token", JSON.stringify(data.token));
              localStorage.setItem("datos", JSON.stringify(data.data[0]));
              this.$router.push("/branch");
            } else {
              this.error = "Datos Incorrectos";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style>
.btnLoginOk {
  background: rgb(89 240 255) !important;
}

.backLoginOndas {
  background: url("../../../public/assets/img/login/onda-top.png");
}

.divBtnLoginOk {
  padding: 0px 30%;
}

.imgUserLogin {
  text-align: center;
  margin: auto;
  background: white;
  height: 225px;
  width: 225px;
  border-radius: 50%;
  padding: 65px 0px 0px 0px;
}

.imgUserLogin img {
  max-width: 80%;
}

.titleLoginInicio {
  color: white;
}

@media (max-width: 600px) {
  .divBtnLoginOk {
    padding: 0px 0px;
  }

  .imgUserLogin img {
    max-width: 80%;
  }
}
</style>
